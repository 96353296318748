import './styles.css';

import React, { ChangeEvent, useCallback, useState } from 'react';

import { Form as FormFormik, Formik, FormikHelpers } from 'formik';
import { Col, FormCheck, FormControl, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { sendRegistration } from '../../../../api/security';
import SubmitButton from '../../../../components/SubmitButton';
import TextField from '../../../../components/TextField';
import { IRegistrationForm } from '../../../../types/security.type';

const SignUp = React.memo(() => {
  const { t } = useTranslation();

  const [isProcess, setProcess] = useState(false);

  const onSubmit = useCallback(
    async (values: IRegistrationForm, helpers: FormikHelpers<any>) => {
      setProcess(true);

      try {
        const { status } = await sendRegistration(values);

        if (status) {
          helpers.resetForm();

          toast.success(t('signup.success'));
        }
      } catch (_) {
        toast.error(t('general.error'));
      } finally {
        setProcess(false);
      }
    },
    [t],
  );

  return (
    <div className="login-item">
      <div className="headline">{t('signup.title')}</div>

      <div className="mb-3">{t('signup.info')}</div>

      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
          cv: undefined,
          accept: false,
        }}
        validationSchema={Yup.object({
          firstname: Yup.string().required(t('general.required')),
          lastname: Yup.string().required(t('general.required')),
          email: Yup.string().email().required(t('general.required')),
          phone: Yup.string()
            .matches(/^\+[\d]{9,15}$/, 'Phone number is not valid. Valid example: +33123456789')
            .required(t('general.required')),
          cv: Yup.mixed().required(t('general.required')),
          subject: Yup.string(),
          message: Yup.string(),
          accept: Yup.boolean().isTrue(t('general.required')),
        })}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <FormFormik>
            <Row>
              <Col>
                <TextField
                  type="text"
                  placeholder={t('general.last_name')}
                  id="lastname"
                  {...formik.getFieldProps('lastname')}
                />
              </Col>

              <Col>
                <TextField
                  type="text"
                  placeholder={t('general.first_name')}
                  id="firstname"
                  {...formik.getFieldProps('firstname')}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Col>
                  <TextField
                    type="email"
                    placeholder={t('general.email')}
                    id="email"
                    {...formik.getFieldProps('email')}
                  />
                </Col>
              </Col>

              <Col>
                <Col>
                  <TextField
                    type="text"
                    placeholder={t('general.phone')}
                    id="phone"
                    {...formik.getFieldProps('phone')}
                  />
                </Col>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <TextField
                  type="file"
                  {...formik.getFieldProps('cv')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const file = e.target.files?.[0];

                    if (file) {
                      formik.setFieldValue('cv', file!);
                    }
                  }}
                  value={undefined}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <TextField
                  type="text"
                  placeholder={t('general.subject')}
                  id="subject"
                  {...formik.getFieldProps('subject')}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <FormControl
                  as="textarea"
                  className="input"
                  rows={3}
                  {...formik.getFieldProps('message')}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormCheck
                  type="checkbox"
                  label={t('signup.accept')}
                  {...formik.getFieldProps('accept')}
                  isInvalid={formik.touched.accept && !!formik.errors.accept}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <SubmitButton
                  label={t('signup.submit')}
                  isProcess={isProcess}
                  isDisabled={isProcess}
                />
              </Col>
            </Row>
          </FormFormik>
        )}
      </Formik>
    </div>
  );
});

export default SignUp;
